<template>
  <v-container>
    <v-row>
      <v-col md="12" lg="3" xl="2">
        <TermsBox
          :terms="config.terms"
          :selectedTerms="selectedTerms"
          @termsChanged="onTermsChanged"
        />
      </v-col>
      <v-col md="12" lg="9" xl="10">
        <CardBox title="Student availablity" minWidth="350" maxWidth="1800">
          <template v-slot:header>
            <!-- <v-icon @click="updateHeatMap">mdi-database-refresh</v-icon> -->
            <v-card v-if="courseFilter" class="caption">
              {{ courseFilterText(courseFilter) }}
              <v-icon @click="resetFilters">mdi-delete</v-icon>
            </v-card>
            <v-card v-else class="caption">
              all courses
            </v-card>
          </template>
          <HeatMap
            :hideRows="['Sunday']"
            :hideCols="['0:00a', '1:00a', '2:00a', '3:00a', '11:00p']"
            :spotSize="spotSize()"
            :rowLabels="defaultDayLabels"
            :colLabels="defaultTimeLabels"
            :values="heatMapValues"
            :gradient="[0.1, 1]"
          />
        </CardBox>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CardBox
          title="Top courses"
          minWidth="200"
          maxWidth="900"
          sm="12"
          lg="8"
        >
          <TopChart :values="topCourses" @chart-click="onChartClick" />
        </CardBox>
      </v-col>
      <!-- </v-row>
    <v-row> -->
      <v-col>
        <CardBox
          title="All courses"
          minWidth="350"
          maxWidth="600"
          sm="12"
          lg="4"
        >
          <template v-slot:header
            ><v-icon @click="onDownloadTimesData"
              >mdi-file-download</v-icon
            ></template
          >

          <v-card>
            <v-card-title>
              <v-text-field
                v-model="courseTableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="courseTableHeaders"
              :items="courseTableItems"
              :search="courseTableSearch"
              :single-select="false"
              item-key="course"
              v-model="courseTableSelection"
              show-select
              @input="onCourseTableInput"
              v-on:click:row="onCourseTableClick"
            >
            </v-data-table>
          </v-card>
        </CardBox>
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import HeatMap from "@/components/HeatMap";
import CardBox from "@/components/CardBox";
import TermsBox from "@/components/TermsBox";
import TopChart from "@/components/TopChart";
import { mapGetters } from "vuex";
import {
  // downloadUserData,
  aggregateTimes,
  aggregateCourses,
} from "@/api/userdata";
import { toCSV, jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Times");

export default {
  name: "Times",
  components: {
    CardBox,
    TermsBox,
    HeatMap,
    TopChart,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
  },

  data: () => ({
    selectedTerms: ["2021Summer", "2021Fall", "2022Spring"],
    heatColor: [155, 81, 224],
    heat: 0.005,
    textHigh: "white",
    textLow: "#828282",
    defaultTimeLabels: [],
    defaultDayLabels: [],
    heatMapValues: undefined,
    topCourses: [],
    topChartSize: 10,
    courseFilter: undefined,
    courseTableHeaders: [
      { text: "id", value: "course", filterable: true },
      { text: "count", value: "count" },
    ],
    courseTableItems: [],
    courseTableSearch: "",
    courseTableSelection: [],
  }),

  methods: {
    onDownloadTimesData() {
      const columns = ["course", "count"];
      const exportSelection =
        this.courseTableSelection && this.courseTableSelection.length > 0
          ? this.courseTableSelection
          : this.courseTableItems;
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, exportSelection)}`,
        fileName: `courses-${jp(this.selectedTerms)}-${
          new Date().toISOString().split("T")[0]
        }.csv`,
      });
    },
    spotSize() {
      // $vuetify.breakpoint.smAndDown ? 18 : 36
      if (this.$vuetify.breakpoint.xsOnly) return 14;
      if (this.$vuetify.breakpoint.smOnly) return 20;
      if (this.$vuetify.breakpoint.mdOnly) return 26;
      if (this.$vuetify.breakpoint.lgOnly) return 38;
      return 48;
    },
    initHeatMap: function(rows, cols) {
      let data = [];
      for (let r = 0; r < rows; r++) {
        let row = new Array(cols);
        row.fill(0);
        data.push(row);
      }
      return data;
    },
    getConfigTimeLabels() {
      return this.config.heatMap.defaultTimeLabels;
    },
    getConfigDayLabels() {
      return this.config.heatMap.defaultDayLabels;
    },
    onTermsChanged(event) {
      this.selectedTerms = _.get(event, "selectedTerms");
      logger.debug(`onTermsChanged: ${jp(this.selectedTerms)}`);
      this.updateControls();
    },
    resetFilters() {
      this.courseFilter = undefined;
      this.courseTableSelection = [];
      this.updateHeatMap();
    },
    courseFilterText(filter) {
      return (filter || []).length > 3
        ? `[${filter[0]},${filter[1]},${filter[2]},...]`
        : jp(filter);
    },
    updateControls() {
      this.updateHeatMap();
      this.updateTopCourseChart();
    },
    updateHeatMap() {
      logger.debug(`terms: ${JSON.stringify(this.terms)}`);
      let terms = this.selectedTerms;
      logger.debug(`terms: ${JSON.stringify(terms)}`);
      logger.debug(`course filter: ${JSON.stringify(this.courseFilter)}`);
      aggregateTimes(this.config, terms, this.courseFilter).then((values) => {
        logger.debug(`aggregateTimes: ${JSON.stringify(values)}`);
        this.heatMapValues = values;
      });
    },
    onCourseTableInput(event) {
      logger.debug(
        `onCourseTableInput, event ${jp(event)}, ${jp(
          this.courseTableSelection
        )}`
      );
      this.courseFilter = event.length > 0 ? _.map(event, "course") : undefined;
      this.updateHeatMap();
    },
    onCourseTableClick(event) {
      logger.debug(`onCourseTableClick`, event);
      this.courseFilter =
        event.length > 0 ? [_.get(event, "course")] : undefined;
      this.updateHeatMap();
    },
    onChartClick(event) {
      logger.debug("onChartClick", event);
      this.courseFilter = [_.get(event, "label")];
      this.updateHeatMap();
    },
    updateTopCourseChart() {
      aggregateCourses(this.selectedTerms).then((data) => {
        this.courseTableItems = data;
        this.topCourses = _.map(
          _.slice(_.sortBy(data, [(r) => -r.count]), 0, this.topChartSize),
          (v) => {
            return { label: v.course, value: v.count };
          }
        );
        logger.debug("complete", this.topCourses);
      });
    },
  },

  created() {
    logger.debug("created");
    this.defaultTimeLabels = this.config.heatMap.defaultTimeLabels;
    this.defaultDayLabels = this.config.heatMap.defaultDayLabels;
    this.heatMapValues = this.initHeatMap(
      this.defaultDayLabels.length,
      this.defaultTimeLabels.length
    );
    this.updateControls();
  },
};
</script>
